import "../css/Menu.css";
import { CustomizedSwitch } from "./Switch";
import { Link } from "react-router-dom";

function Menu() {
  return (
    <div>
      <header>
        <nav className="Menu">
          <CustomizedSwitch />
          <Link className="MenuItem" to={"/"}>
            Home
          </Link>
          <div className="divider">|</div>
          <Link className="MenuItem" to={"/career"}>
            Career
          </Link>
          <div className="divider">|</div>
          <Link className="MenuItem" to={"/creative"}>
            Creative
          </Link>
        </nav>
      </header>
    </div>
  );
}

export default Menu;
