import "../css/Home.css";
import "../css/Content.css";
import "../css/Body.css";

import Menu from "./Menu";
import Text from "./Text";
import Footer from "./Footer";

import ProfilePicture from "./ProfilePicture";

export default function Home() {
  return (
    <div className="Home">
      <Menu />
      <div className="Body">
        <div className="Content">
          {
            <div>
              <Text
                text={
                  <p>
                    I'm Christoph – a software developer by occupation and
                    musical creative by heart based in <b>Vienna, Austria</b>.
                    My whole career so far has been with <b>IBM</b>, starting as
                    an IT apprentice, advancing to Junior Technical Sales and
                    finally work as a Technology Engineer in the Client
                    Engineering team. Mostly I build <b>backend</b>{" "}
                    orchestrators with Python FastApi for AI use cases.
                  </p>
                }
                title="Hello,"
              />
            </div>
          }
        </div>
        <Footer />
      </div>
      <ProfilePicture picture={"/media/pictures/proud-small.jpg"} />
    </div>
  );
}
