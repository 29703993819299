import "../css/Text.css";

function Text(props) {
  return (
    <div className="Text">
      <h1>{props.title}</h1>
      <div>{props.text}</div>
    </div>
  );
}

export default Text;
