import "../css/Music.css";
import Menu from "./Menu";
import Footer from "./Footer";
import ProfilePicture from "./ProfilePicture";
import { useRef, useState } from "react";

export default function Music() {
  return (
    <div className="Music">
      <Menu></Menu>
      <div className="Body">
        <div className="Content">
          {
            <div>
              <h1>Muisc Previews</h1>
              <div className="songList">
                <Song
                  title="float.wav"
                  songStyle="ambient"
                  src="/media/music/floating.wav"
                  type="audio/wav"
                  index={0}
                />
                <Song
                  title="fe.wav"
                  songStyle="experimental"
                  src="/media/music/fe.wav"
                  type="audio/wav"
                  index={1}
                />
                <Song
                  title="the_good_ones.wav"
                  songStyle="nostalgic"
                  src="/media/music/go.wav"
                  type="audio/wav"
                  index={2}
                />
                <Song
                  title="kinderzimmer.wav"
                  songStyle="weighted"
                  src="/media/music/kz.wav"
                  type="audio/wav"
                  index={3}
                />
                <Song
                  title="marimbajam.wav"
                  songStyle="fun"
                  src="/media/music/mj.wav"
                  type="audio/wav"
                  index={4}
                />
                <Song
                  title="pfütze.wav"
                  songStyle="experimental"
                  src="/media/music/pf.wav"
                  type="audio/wav"
                  index={5}
                />
              </div>
            </div>
          }
        </div>
        <Footer></Footer>
      </div>
      <ProfilePicture picture="/media/pictures/band-practice-small.jpg"></ProfilePicture>
    </div>
  );
}

function Song(props) {
  const audioRef = useRef(null); // Reference for the audio element
  const [isPlaying, setIsPlaying] = useState(false); // State to track whether the song is playing

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying); // Toggle the play/pause state
    }
  };

  const handleError = (e) => {
    console.error("Error loading audio file:", e);
  };

  const totalSongs = 6; // Pass the total number of songs to the component
  const reverseIndex = totalSongs - props.index - 1; // Calculate reverse index
  const exponentialDelay = 0.5 * Math.pow(0.9, reverseIndex); // Apply delay based on reverse index
  console.log(exponentialDelay);

  return (
    <div
      className={`Song ${isPlaying ? "playing" : ""}`}
      onClick={togglePlayPause}
      style={{ animationDelay: `${exponentialDelay}s` }}
    >
      <h2>{props.title}</h2>
      <label>{props.songStyle}</label>
      <audio
        ref={audioRef}
        src={props.src} // Dynamically pass the source of the audio
        type={props.type || "audio/wav"} // Default to "audio/wav"but allow customization
        index={props.index}
        controls
        onError={handleError} // Log error to the console
        onEnded={togglePlayPause}
      ></audio>
    </div>
  );
}
