import "../css/Footer.css";
import { emailAddress } from "../data/email";

function Footer() {
  return (
    <div>
      <div className="FooterHint">
        <a href="#Footer">Socials</a>
        <img src="/media/icons/down-arrow.svg" alt="down arrow"></img>
      </div>
      <div id="Footer" className="Footer">
        <FooterItem
          label="LinkedIn"
          href="https://www.linkedin.com/in/christoph-kormesser-253433158/"
          icon="/media/icons/linkedin.jpeg"
        />
        <FooterItem
          label="GitHub"
          href="https://github.com/christophkormesser"
          icon="/media/icons/github-cat.gif"
        />
        <FooterItem
          label="Mail"
          href={"mailto:" + emailAddress}
          icon="/media/icons/mail.svg"
        />
      </div>
    </div>
  );
}

function FooterItem(props) {
  return (
    <div className="FooterItem">
      <img src={props.icon} alt={props.label + " icon"}></img>
      <a href={props.href} target="_blank" rel="noreferrer">
        {props.label}
      </a>
    </div>
  );
}

export default Footer;
