import { Menu } from "@mui/material";
import Footer from "./Footer";
import Text from "./Text";
import ProfilePicture from "./ProfilePicture";

import "../css/Career.css";

export default function Career() {
  return (
    <div className="Home">
      <Menu></Menu>
      <div className="Body">
        <div className="Content">
          {
            <div>
              <Text
                // title="Timeline"
                text={
                  <div>
                    <h2>Work Experience</h2>
                    <Timeline
                      timelineItems={
                        <>
                          <TimelineItem
                            text="2024 IBM Platform Engineer (full time)"
                            description={
                              "Continued full time, only the job title changed."
                            }
                          ></TimelineItem>
                          <TimelineItem
                            text="2023 IBM Technology Engineer (part time)"
                            description={
                              "Back to tech: started as a backend developer for AI projects."
                            }
                          ></TimelineItem>
                          <TimelineItem
                            text="2021 IBM Junior Technical Sales (part time)"
                            description={
                              "Showcased IBM's AI products for clients and worked on Data & AI RFPs."
                            }
                          ></TimelineItem>
                          <TimelineItem
                            text="2017 IBM IT Apprentice (full time)"
                            description={
                              "Started my career building AI demos for technical sellers with our cloud products and completed an abroad internhip in Aalborg, Denmark."
                            }
                          ></TimelineItem>
                        </>
                      }
                    ></Timeline>
                    <h2>Education</h2>
                    <Timeline
                      timelineItems={
                        <>
                          <TimelineItem
                            text="2024 BSc. in Engineering at FH Techikum
                        Vienna"
                            description={
                              "Conducted a comparative analysis of centralized and decentralized security models in microservices architectures for my thesis."
                            }
                          ></TimelineItem>
                          <TimelineItem
                            text="2021 IT Apprenticeship Exam"
                            description={"Graduated with honors."}
                          ></TimelineItem>
                        </>
                      }
                    ></Timeline>
                  </div>
                }
              ></Text>
            </div>
          }
        </div>
        <Footer></Footer>
      </div>
      <ProfilePicture picture={"/media/pictures/chris-3.jpg"}></ProfilePicture>
    </div>
  );
}

function Timeline({ timelineItems }) {
  return (
    <div>
      <ul className="Timeline">{timelineItems}</ul>
    </div>
  );
}

function TimelineItem({ text, description }) {
  return (
    <li className="TimelineItem">
      <p>{text}</p>
      <p className="Info">&#9432;</p>
      <label className="Description">{description}</label>
    </li>
  );
}
