import "../css/ProfilePicture.css";
import { useState, useEffect } from "react";

function ProfilePicture(props) {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(false); // Reset the animation
    setTimeout(() => setAnimate(true), 10); // Add the class back after a small delay to retrigger the animation
  }, [props.picture]);

  return (
    <div className={"ProfilePicture"}>
      <img
        src={props.picture}
        className={animate ? "fade-in" : ""}
        alt="A depiction of myself"
      ></img>
    </div>
  );
}

export default ProfilePicture;
